import React, { useEffect } from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Footer from "@components/Footer/Footer";
import HeaderNav from "@components/HeaderNavV2/HeaderNav";
import Layout from "@components/layout";
import Seo from "@components/seo";
import Button from "@atoms/Button/Button";
import Divider from "@atoms/Divider/Divider";
import Section from "@atoms/Section/Section";
import Text from "@atoms/Text/Text";
import Title from "@atoms/Title/Title";
import Module from "@atoms/Module/Module";
import HeaderMinimal from "@components/HeaderMinimal/HeaderMinimal";
import AboutTeam from "@components/AboutTeam";
import Tile from "@atoms/Tile/Tile";
import { withLocale, withTracking } from "@hocs";
import { useTracking } from "@hooks";
export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        headerMobileImage: file(
            relativePath: { eq: "jobs/header-jobs-mobile.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        headerDesktopImage: file(
            relativePath: { eq: "jobs/header-jobs-desktop.jpg" }
        ) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 1440) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamNicolas: file(relativePath: { eq: "team/team_nicolas.png" }) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamAdonis: file(relativePath: { eq: "team/team_adonis.png" }) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamHiep: file(relativePath: { eq: "team/team_hiep.png" }) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamHan: file(relativePath: { eq: "team/team_han.png" }) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamRomina: file(relativePath: { eq: "team/team_romina.png" }) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamFq: file(relativePath: { eq: "team/team-fg.png" }) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamAg: file(relativePath: { eq: "team/team-ag.png" }) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamRz: file(relativePath: { eq: "team/team-rz.png" }) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamVd: file(relativePath: { eq: "team/team-vd.png" }) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamNw: file(relativePath: { eq: "team/team-nw.png" }) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamGc: file(relativePath: { eq: "team/team-gc.jpg" }) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamCj: file(relativePath: { eq: "team/team-cj.png" }) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamRk: file(relativePath: { eq: "team/team-rk.jpg" }) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamNd: file(relativePath: { eq: "team/team-nd.jpg" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamAk: file(relativePath: { eq: "team/team-ak.jpg" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamDh: file(relativePath: { eq: "team/team-dh.jpg" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

const JOBS = [
	{
		title: "Account Management & Kommunikation",
		subtitle: "100%, Zürich",
		url: "https://join.com/companies/aepsy/13302347-account-management-und-kommunikation",
	},
];

// const JOBS = [];

const JobsPage = ({ data: static_images }) => {
	const { t } = useTranslation();
	const { trackPageView } = useTracking();

	useEffect(() => {
		trackPageView();
	}, []);

	// Header mobile
	const static_images_sources = [
		static_images.headerMobileImage.childImageSharp.fluid,
		{
			...static_images.headerDesktopImage.childImageSharp.fluid,
			media: `(min-width: 750px)`,
		},
	];

	return (
		<Layout>
			<Seo
				title={t("jobs.seo.title")}
				description={t("jobs.seo.description")}
			/>
			<div className="global_theme-green">
				<HeaderNav theme="white" />
				<Section theme="light">
					<HeaderMinimal
						theme="light"
						title={t("jobs.seo.header.title")}
						btnPrimary={
							<AnchorLink offset={0} href="#openPosition">
								<Button
									variant="primary"
									theme="white"
									label={t("view.open.position")}
									size="l"
								/>
							</AnchorLink>
						}
						imageSrc={static_images_sources}
					/>

					<div id="mehr">
						<AboutTeam
							staticImages={static_images}
							title="job.mission.intro.title"
							quote="job.mission.intro.quote"
						/>
					</div>

					<div id="openPosition">
						<Section container="short" spacingTop="m" theme="light">
							<Title theme="dark" align="center">
								{t("view.open.position")}
							</Title>

							<div className="g_1_4 g_center">
								<Divider spacing="m" />
							</div>

							<div className="g_2_3 g_center">
								<Text align="center">
									<Trans
										i18nKey="jobs.and.career.description"
										components={{ br: <br /> }}
									/>
								</Text>
							</div>
						</Section>
						<Section
							theme="light"
							container="large"
							spacingTop="s"
							spacingBottom="m"
						>
							<div className="g_1_2 g_center">
								{JOBS.length === 0 ? (
									<>
										<Module radius="m">
											<Title size="m" noMargin>
												{t("jobs.no.available.position.title")}
											</Title>
										</Module>
										<Section spacingTop="xxs">
											<Text size="s">
												{t("jobs.no.available.position.subtitle")}
												<a href="mailto:jobs@aepsy.com">jobs@aepsy.com</a>
											</Text>
										</Section>
									</>
								) : (
									<>
										{JOBS.map((item: any) => (
											<a href={item.url} target="_blank" rel="noreferrer">
												<Tile
													title={t(item.title)}
													subtitle={t(item.subtitle)}
													elevation="s"
													variant={null}
												/>
											</a>
										))}
										<Section spacingTop="s">
											<a
												href="https://join.com/companies/aepsy"
												target="_blank"
												rel="noreferrer"
											>
												<Button
													align="center"
													label={t("jobs.view.all.position.cta")}
												/>
											</a>
										</Section>
									</>
								)}
							</div>
						</Section>
					</div>
				</Section>

				{/* Footer */}
				<Footer variant="extended" />
			</div>
		</Layout>
	);
};

export default withTracking(withLocale(JobsPage));
