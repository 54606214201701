import Link from "@components/Link";
import Img from "gatsby-image";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import Button from "@atoms/Button/Button";
import Divider from "@atoms/Divider/Divider";
import Section from "@atoms/Section/Section";
import Text from "@atoms/Text/Text";
import Title from "@atoms/Title/Title";
import Card from "@components/Card/Card";
import CardGallery from "@components/CardGallery/CardGallery";
import FatNumber from "@components/FatNumber/FatNumber";
// Modules
import React from "react";
import TeamCard from "@components/TeamCard/TeamCard";

const AboutTeam = ({ staticImages, title, quote }) => {
	const { t } = useTranslation();
	return (
		<>
			{/* Team intro */}
			<Section container="large" spacingTop="l">
				<Section spacingBottom="s">
					<Title size="xl">
						{t(title || "about.team.section.intro.title")}
					</Title>
				</Section>
				<Title size="xl" theme="soft">
					<Trans
						i18nKey={quote || "about.team.section.intro.quote"}
						components={{
							br: <br />,
							bold: (
								<Title theme="dark" tag="span">
									<></>
								</Title>
							),
						}}
					/>
				</Title>
			</Section>

			<div className="g_hide_from_tablet_portrait">
				<Section container="large" spacingTop="xl"></Section>
			</div>

			{/* Team gallery */}
			<Section container="large" spacingBottom="s" spacingTop="s">
				<CardGallery size="3" layout="cascade" variant="mobileTwoColumn">
					<TeamCard
						image={
							<Img
								fluid={staticImages.teamNicolas.childImageSharp.fluid}
								alt="Nicolas Egger - Profile"
							/>
						}
						name="Nicolas Egger"
						role={t("about.team.section.nicolas.role")}
					/>
					<TeamCard
						image={
							<Img
								fluid={staticImages.teamRomina.childImageSharp.fluid}
								alt="Romina Reginold - Profile"
							/>
						}
						name="Romina Reginold"
						role={t("about.team.section.romina.role")}
					/>
					<TeamCard
						image={
							<Img
								fluid={staticImages.teamAdonis.childImageSharp.fluid}
								alt="Adonis Bou Chakra - Profile"
							/>
						}
						name="Adonis Bou Chakra"
						role={t("about.team.section.adonis.role")}
					/>
					<TeamCard
						image={
							<Img
								fluid={staticImages.teamHan.childImageSharp.fluid}
								alt="Han Phan - Profile"
							/>
						}
						name="Han Phan"
						role={t("about.team.section.han.role")}
					/>
					<TeamCard
						image={
							<Img
								fluid={staticImages.teamHiep.childImageSharp.fluid}
								alt="Hiep Doan - Profile"
							/>
						}
						name="Hiep Doan"
						role={t("about.team.section.hiep.role")}
					/>
					<TeamCard
						image={
							<Img
								fluid={staticImages.teamFq.childImageSharp.fluid}
								alt="Franziska Giesen - Profile"
							/>
						}
						name="Franziska Giesen"
						role={t("about.team.section.fg.role")}
					/>
					<TeamCard
						image={
							<Img
								fluid={staticImages.teamAg.childImageSharp.fluid}
								alt="Anastasiya Gnitko - Profile"
							/>
						}
						name="Anastasiya Gnitko"
						role={t("about.team.section.ag.role")}
					/>
					<TeamCard
						image={
							<Img
								fluid={staticImages.teamRz.childImageSharp.fluid}
								alt="Ronan Zimmerman - Profile"
							/>
						}
						name="Dr. Ronan Zimmerman"
						role={t("about.team.section.rz.role")}
					/>
					<TeamCard
						image={
							<Img
								fluid={staticImages.teamVd.childImageSharp.fluid}
								alt="Vu Do - Profile"
							/>
						}
						name="Vu Do"
						role={t("about.team.section.vd.role")}
					/>
					<TeamCard
						image={
							<Img
								fluid={staticImages.teamNw.childImageSharp.fluid}
								alt="Noel Walter - Profile"
							/>
						}
						name="Noel Walter"
						role={t("about.team.section.nw.role")}
					/>
					<TeamCard
						image={
							<Img
								fluid={staticImages.teamGc.childImageSharp.fluid}
								alt="Gloria Czernin - Profile"
							/>
						}
						name="Gloria Czernin"
						role={t("about.team.section.gc.role")}
					/>
					<TeamCard
						image={
							<Img
								fluid={staticImages.teamCj.childImageSharp.fluid}
								alt="Celine Jordan - Profile"
							/>
						}
						name="Céline Jordan"
						role={t("about.team.section.cj.role")}
					/>
					<TeamCard
						image={
							<Img
								fluid={staticImages.teamRk.childImageSharp.fluid}
								alt="Rian Kocher - Profile"
							/>
						}
						name="Rian Kocher"
						role={t("about.team.section.rk.role")}
					/>
					<TeamCard
						image={
							<Img
								fluid={staticImages.teamNd.childImageSharp.fluid}
								alt="Nam Duong - Profile"
							/>
						}
						name="Nam Duong"
						role={t("about.team.section.nd.role")}
					/>
					<TeamCard
						image={
							<Img
								fluid={staticImages.teamAk.childImageSharp.fluid}
								alt="Anne Kohlbrenner - Profile"
							/>
						}
						name="Anne Kohlbrenner"
						role={t("about.team.section.ak.role")}
					/>
					<TeamCard
						image={
							<Img
								fluid={staticImages.teamDh.childImageSharp.fluid}
								alt="Dorottya Horvath - Profile"
							/>
						}
						name="Dorottya Horvath"
						role={t("about.team.section.dh.role")}
					/>
				</CardGallery>
			</Section>

			{/* Unser Plan */}
			<Section container="short" spacingTop="m" theme="light">
				<Title theme="dark" align="center">
					{t("about.our.plan.title")}
				</Title>

				<div className="g_1_4 g_center">
					<Divider spacing="m" />
				</div>

				<div className="g_2_3 g_center">
					<Text align="center">
						<Trans
							i18nKey={"about.our.plan.description"}
							components={{ br: <br /> }}
						/>
					</Text>
				</div>
			</Section>
			<Section theme="light" container="large" spacingTop="l" spacingBottom="m">
				<CardGallery size="3" layout="cascade" variant="mobileInline">
					<Card
						format="v"
						variant="heroTitle"
						visual={<FatNumber hero="1" />}
						title={t("about.our.plan.step.one.title")}
						text={t("about.our.plan.step.one.text")}
					/>
					<Card
						format="v"
						variant="heroTitle"
						visual={<FatNumber hero="2" />}
						title={t("about.our.plan.step.two.title")}
						text={t("about.our.plan.step.two.text")}
					/>
					<Card
						format="v"
						variant="heroTitle"
						visual={<FatNumber hero="3" />}
						title={t("about.our.plan.step.three.title")}
						text={t("about.our.plan.step.three.text")}
					/>
				</CardGallery>
				{/* <Divider spacing="s" invisible /> */}

				<Link to="/approach/">
					<Button
						variant="naked"
						theme="highlighted"
						label={t("about.our.plan.cta.label")}
						iconArrow
						align="center"
					/>
				</Link>
			</Section>

			{/* Die Gründungsgeschichte */}

			<Section theme="dark" container="large" spacingBottom="l" spacingTop="l">
				<Title tag="p" size="xl" theme="white">
					{t("about.founding.story.title")}
					<br />
					<br />
				</Title>
				<Title tag="p" size="xl" theme="white">
					<Trans
						i18nKey={"about.founding.story.text"}
						components={{ br: <br /> }}
					/>
				</Title>
			</Section>
		</>
	);
};

export default AboutTeam;
